class User {
    constructor(id = '', firstName = '', lastName = '', email = '', isLoggedIn = false, phone = '', accountType = '', status = '', profileImage = '') {
      this.id = id;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.isLoggedIn = isLoggedIn;
      this.phone = phone;
      this.accountType = accountType;
      this.status = status;
      this.profileImage = profileImage;
    }
  
    static fromJson({
      id, firstName, lastName, email, isLoggedIn, phone, accountType, status,
    }) {
      return new User(id, firstName, lastName, email, isLoggedIn, phone, accountType, status);
    }
  }
  
  export default User;