import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from "gatsby"
import firebaseConfig from '../../../firebase-config.json';
import User from '../../models/User.class';

export const CurrentUserContext = React.createContext(null);

export class FirebaseComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: new User(),
    };

    this.initializeFirebaseApp();
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    this.firebaseSetOnAuthChange();
  }

  initializeFirebaseApp() {
    try {
      const { appName } = this.props;
      if (appName) {
        firebase.initializeApp(firebaseConfig, appName);
      } else {
        firebase.initializeApp(firebaseConfig);
      }
    } catch (error) {
      console.log(error);
    }
  }

  firebaseSetOnAuthChange() {
    let userInstance = null;
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          await firebase.firestore().collection('users').where("id", "==", user.uid).get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                const userData = doc.data();
                userInstance = new User(user.uid, userData.firstName, userData.lastName, user.email, true, userData.phone, userData.account_type, userData.status, userData.profileImage);
              });
              this.setState({
                loggedInUser: userInstance,
              }, () => {
                navigate("/admin/add-recipe");
              });
            });
        } else {
          this.setState({
            loggedInUser: new User(),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }

  }

  logOut() {
    try {
      firebase.auth().signOut().then(() => console.log('User logged out!'));
    } catch (error) {
      console.log(error);
    }

  }

  render() {
    const { loggedInUser } = this.state;
    const { children } = this.props;
    return (
      <>
        <CurrentUserContext.Provider value={{ ...loggedInUser, logOut: this.logOut }}>
          {children}
        </CurrentUserContext.Provider>
      </>
    );
  }
}

FirebaseComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
};

FirebaseComponent.defaultProps = {
  children: {},
};


export default FirebaseComponent;
