import PropTypes from "prop-types"
import React, { useContext } from "react"
import { CurrentUserContext } from '../components/Firebase';
import { isUserLoggedIn } from "../utils/utils";

const Header = ({ siteTitle }) => {
  const user = useContext(CurrentUserContext);
  const logOut = () => {
    if (!user) {
      return;
    }
    user.logOut();
  };
  return (
    <nav className="navbar navbar-light bg-light static-top">
      <div className="container">
        <a className="navbar-brand" href="/">Cooks Meals</a>
        {/*<a className="btn btn-primary" href="#">Sign In</a>*/}
      </div>
      {user && isUserLoggedIn(user) && (
      <div className="pull-right">
        <button onClick={logOut}>Logout</button>
      </div>
      )}
    </nav>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
